export default {
  headers: [
    {
      id: 'mobileHeader',
      logo: {
        alt: 'logo',
        src: 'https://si-homelight.s3.amazonaws.com/sites/silba/logo.png',
        url: 'https://www.thekinnegroup.com/',
      },
      breakpoint: 1000,
      desktopMenu: {
        type: 'default', // default, split, center, single, one-offs
        disableOnHoverMenuExpand: false,
        logo: {
          show: false,
          reverseOrder: false,
          containerClassName: '',
          imageClassName: '',
        },
        headerClassName: '',
        headerSectionsClassName: '',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: '',
        itemClassName: '',
        menuClassName: '',
        menuLabelActiveClassName: '',
        menuItemClassName: '',
        subMenuClassName: '',
        subMenuLabelActiveClassName: '',
        subMenuItemClassName: '',
        items: [],
      },
      mobileMenu: {
        logo: {
          show: false,
          containerClassName: '',
          imageClassName: '',
        },
        type: 'mobile-default', // mobile-default, mobile-reversed, mobile-center
        menuOpener: {
          type: 'hamburger',
          className: '',
          hide: true,
          reverse: false,
        },
        headerClassName: 'on-silba-mobile-header-top',
        headerSectionsClassName: '',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: '',
        mobileMenuClassName: '',
        itemClassName: 'on-silba-mobile-top-item',
        menuClassName: '',
        menuLabelActiveClassName: '',
        menuItemClassName: '',
        subMenuClassName: '',
        subMenuItemClassName: '',
        headerItems: [
          {
            id: 'login-item',
            content: 'Login / Register',
            url: 'https://www.thekinnegroup.com/property-search/property-tracker/',
            className: 'on-silba-special-item',
          },
          {
            id: 'blog-item',
            content: 'Blog',
            url: 'https://www.thekinnegroup.com/blog/',
            className: 'on-silba-special-item',
          },
          {
            id: 'header-silba-phone-item',
            iconSet: 'fa',
            className: 'phone',
            content: '(281) 610-3625',
            url: 'tel:+12816103625',
          },
        ],
        items: [],
      },
    },
    {
      id: 'hiddenHeader',
      logo: {
        alt: 'logo',
        src: '',
        url: '',
      },
      breakpoint: -1,

      desktopMenu: {
        type: 'default', // default, split, center, single, one-offs
        disableOnHoverMenuExpand: false,
        logo: {
          show: false,
          reverseOrder: false,
          containerClassName: '',
          imageClassName: '',
        },
        headerClassName: '',
        headerSectionsClassName: '',
        headerClassNameOverride: 'on-silba-hidden-header',
        headerSectionsClassNameOverride: '',
        itemClassName: '',
        menuClassName: '',
        menuLabelActiveClassName: '',
        menuItemClassName: '',
        subMenuClassName: '',
        subMenuLabelActiveClassName: '',
        subMenuItemClassName: '',
        items: [],
      },
      mobileMenu: {
        logo: {
          show: false,
          containerClassName: '',
          imageClassName: '',
        },
        type: 'mobile-default', // mobile-default, mobile-reversed, mobile-center
        menuOpener: {
          type: 'hamburger',
          className: '',
          hide: true,
          reverse: false,
        },
        headerClassName: '',
        headerSectionsClassName: '',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: '',
        mobileMenuClassName: '',
        itemClassName: '',
        menuClassName: '',
        menuLabelActiveClassName: '',
        menuItemClassName: '',
        subMenuClassName: '',
        subMenuItemClassName: '',
        headerItems: [],
        items: [],
      },
    },
    {
      id: 'mainHeader',
      logo: {
        alt: 'logo',
        src: 'https://si-homelight.s3.amazonaws.com/sites/silba/logo.png',
        url: 'https://www.thekinnegroup.com/',
      },
      breakpoint: 992,
      desktopMenu: {
        type: 'center', // default, split, center, single, one-offs
        disableOnHoverMenuExpand: false,
        logo: {
          show: true,
          reverseOrder: false,
          containerClassName: 'on-silba-desktop-logo-c',
          imageClassName: 'on-silba-desktop-logo',
        },
        headerClassName: 'on-silba-desktop-header',
        headerSectionsClassName: 'on-silba-desktop-header-sections',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: '',
        itemClassName: 'on-silba-desktop-item',
        menuClassName: 'on-silba-desktop-menu',
        menuLabelActiveClassName: 'on-silba-desktop-label',
        menuItemClassName: 'on-silba-desktop-menu-item',
        subMenuClassName: '',
        subMenuLabelActiveClassName: 'on-silba-desktop-sub-label',
        subMenuItemClassName: 'on-silba-desktop-sub-menu-item',
        items: [
          {
            id: 'search-item',
            content: 'Search',
            url: 'https://www.thekinnegroup.com/property-search/search-form/',
            menuItems: [
              {
                id: 'search-item-advanced',
                content: 'Advanced Search',
                url: 'https://www.thekinnegroup.com/property-search/search-form/',
              },
              {
                id: 'search-item-map',
                content: 'Search by Map',
                url: 'https://www.thekinnegroup.com/property-search/results/?searchtype=3',
              },
              {
                id: 'search-item-property-tracker',
                content: 'Property Tracker',
                url: 'https://www.thekinnegroup.com/property-search/property-tracker/',
              },
              {
                id: 'search-item-featured-listings',
                content: 'Featured Listings',
                url: 'https://www.thekinnegroup.com/featured-listings/',
              },
              {
                id: 'search-item-all-cash',
                content: 'Become an ALL-CASH BUYER',
                url: 'https://cash-offer-silba.netlify.app/',
              },
              {
                id: 'search-item-trade-in',
                content: 'Buy Before You Sell - TRADE-IN',
                url: 'https://silba-develop.netlify.app/',
              },
            ],
          },
          {
            id: 'communities-item',
            content: 'Communities',
            url: 'https://www.thekinnegroup.com/communities/',
          },
          {
            id: 'menu-item-buyers',
            content: 'Buyers',
            url: 'https://www.thekinnegroup.com/buyers/',
            menuItems: [
              {
                id: 'menu-item-buyers-buyers',
                content: 'Buyers',
                url: 'https://www.thekinnegroup.com/buyers/',
              },
              {
                id: 'menu-item-buyers-calculator',
                content: 'Mortgage Calculator',
                url: 'https://www.thekinnegroup.com/buyers/mortgage-calculator/',
              },
              {
                id: 'menu-item-buyers-mortgage',
                content: 'Mortgage Pre-Approval',
                url: 'https://www.thekinnegroup.com/buyers/mortgage-pre-approval/',
              },
              {
                id: 'menu-item-buyers-first-time',
                content: 'First Time Buyers',
                url: 'https://www.thekinnegroup.com/buyers/first-time-buyers/',
              },
              {
                id: 'menu-item-buyers-making-offer',
                content: 'Making an Offer',
                url: 'https://www.thekinnegroup.com/buyers/making-an-offer/',
              },
              {
                id: 'menu-item-buyers-closing-costs',
                content: 'What Are Closing Costs?',
                url: 'https://www.thekinnegroup.com/buyers/what-are-closing-costs/',
              },
              {
                id: 'menu-item-buyers-escrow',
                content: 'Escrow: Now What?',
                url: 'https://www.thekinnegroup.com/buyers/escrow-now-what/',
              },
              {
                id: 'menu-item-buyers-financial-terms',
                content: 'Financial Terms Glossary',
                url: 'https://www.thekinnegroup.com/buyers/financial-terms-glossary/',
              },
              {
                id: 'menu-item-buyers-personalized',
                content: 'Personalized Home Search',
                url: 'https://www.thekinnegroup.com/buyers/personalized-home-search/',
              },
            ],
          },
          {
            id: 'menu-item-sellers',
            content: 'Sellers',
            url: 'https://www.thekinnegroup.com/sellers/',
            menuItems: [
              {
                id: 'menu-item-sellers-selling-home',
                content: 'Selling a Home',
                url: 'https://www.thekinnegroup.com/sellers/',
              },
              {
                id: 'menu-item-sellers-pricing-home',
                content: 'Pricing Your Home',
                url: 'https://www.thekinnegroup.com/sellers/pricing-your-home/',
              },
              {
                id: 'menu-item-sellers-marketing-home',
                content: 'Marketing Your Home',
                url: 'https://www.thekinnegroup.com/sellers/marketing-your-home/',
              },
              {
                id: 'menu-item-sellers-showing-home',
                content: 'Showing Your Home',
                url: 'https://www.thekinnegroup.com/sellers/showing-your-home/',
              },
              {
                id: 'menu-item-sellers-adding-value',
                content: 'Adding Value',
                url: 'https://www.thekinnegroup.com/sellers/adding-value/',
              },
              {
                id: 'menu-item-sellers-free-market-analysis',
                content: 'Free Market Analysis',
                url: 'https://www.thekinnegroup.com/sellers/free-market-analysis/',
              },
            ],
          },
          {
            id: 'menu-item-about',
            content: 'About',
            url: 'https://www.thekinnegroup.com/about/',
          },
          {
            id: 'menu-item-contact',
            content: 'Contact',
            url: 'https://www.thekinnegroup.com/contact/',
          },
          {
            id: 'login-item',
            content: 'Login / Register',
            url: 'https://www.thekinnegroup.com/property-search/property-tracker/',
            className: 'on-silba-special-item',
          },
          {
            id: 'blog-item',
            content: 'Blog',
            url: 'https://www.thekinnegroup.com/blog/',
            className: 'on-silba-special-item',
          },
        ],
      },
      mobileMenu: {
        logo: {
          show: true,
          containerClassName: '',
          imageClassName: 'on-silba-mobile-logo',
        },
        type: 'mobile-default', // mobile-default, mobile-reversed, mobile-center
        menuOpener: {
          type: 'hamburger',
          className: '',
          hide: false,
          reverse: false,
        },
        headerClassName: 'on-silba-mobile-header',
        headerSectionsClassName: '',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: '',
        mobileMenuClassName: 'on-silba-mobile-menu',
        itemClassName: 'on-silba-mobile-item',
        menuClassName: 'on-silba-mobile-folder',
        menuLabelActiveClassName: '',
        menuItemClassName: 'on-silba-mobile-folder-item',
        subMenuClassName: '',
        subMenuItemClassName: '',
        headerItems: [],
        items: [
          {
            id: 'home-item-mobile',
            content: 'Home',
            url: 'https://www.thekinnegroup.com/',
          },
          {
            id: 'blog-item-mobile',
            content: 'Blog',
            url: 'https://www.thekinnegroup.com/blog/',
          },
          {
            id: 'search-mobile',
            content: 'Search',
            menuItems: [
              {
                id: 'search-item-mobile-advanced',
                content: 'Advanced Search',
                url: 'https://www.thekinnegroup.com/property-search/search-form/',
              },
              {
                id: 'search-item-mobile-map',
                content: 'Search by Map',
                url: 'https://www.thekinnegroup.com/property-search/results/?searchtype=3',
              },
              {
                id: 'search-item-mobile-property-tracker',
                content: 'Property Tracker',
                url: 'https://www.thekinnegroup.com/property-search/property-tracker/',
              },
              {
                id: 'search-item-mobile-featured-listings',
                content: 'Featured Listings',
                url: 'https://www.thekinnegroup.com/featured-listings/',
              },
              {
                id: 'search-item-mobile-all-cash',
                content: 'Become an ALL-CASH BUYER',
                url: 'https://cash-offer-silba.netlify.app/',
              },
              {
                id: 'search-item-mobile-trade-in',
                content: 'Buy Before You Sell - TRADE-IN',
                url: 'https://silba-develop.netlify.app/',
              },
            ],
          },
          {
            id: 'communities-item-mobile',
            content: 'Communities',
            url: 'https://www.thekinnegroup.com/communities/',
          },
          {
            id: 'menu-item-mobile-buyers',
            content: 'Buyers',
            menuItems: [
              {
                id: 'menu-item-mobile-buyers-buyers',
                content: 'Buyers',
                url: 'https://www.thekinnegroup.com/buyers/',
              },
              {
                id: 'menu-item-mobile-buyers-calculator',
                content: 'Mortgage Calculator',
                url: 'https://www.thekinnegroup.com/buyers/mortgage-calculator/',
              },
              {
                id: 'menu-item-mobile-buyers-mortgage',
                content: 'Mortgage Pre-Approval',
                url: 'https://www.thekinnegroup.com/buyers/mortgage-pre-approval/',
              },
              {
                id: 'menu-item-mobile-buyers-first-time',
                content: 'First Time Buyers',
                url: 'https://www.thekinnegroup.com/buyers/first-time-buyers/',
              },
              {
                id: 'menu-item-mobile-buyers-making-offer',
                content: 'Making an Offer',
                url: 'https://www.thekinnegroup.com/buyers/making-an-offer/',
              },
              {
                id: 'menu-item-mobile-buyers-closing-costs',
                content: 'What Are Closing Costs?',
                url: 'https://www.thekinnegroup.com/buyers/what-are-closing-costs/',
              },
              {
                id: 'menu-item-mobile-buyers-escrow',
                content: 'Escrow: Now What?',
                url: 'https://www.thekinnegroup.com/buyers/escrow-now-what/',
              },
              {
                id: 'menu-item-mobile-buyers-financial-terms',
                content: 'Financial Terms Glossary',
                url: 'https://www.thekinnegroup.com/buyers/financial-terms-glossary/',
              },
              {
                id: 'menu-item-mobile-buyers-personalized',
                content: 'Personalized Home Search',
                url: 'https://www.thekinnegroup.com/buyers/personalized-home-search/',
              },
            ],
          },

          {
            id: 'menu-item-mobile-sellers',
            content: 'Sellers',
            menuItems: [
              {
                id: 'menu-item-mobile-sellers-selling-home',
                content: 'Selling a Home',
                url: 'https://www.thekinnegroup.com/sellers/',
              },
              {
                id: 'menu-item-mobile-sellers-pricing-home',
                content: 'Pricing Your Home',
                url: 'https://www.thekinnegroup.com/sellers/pricing-your-home/',
              },
              {
                id: 'menu-item-mobile-sellers-marketing-home',
                content: 'Marketing Your Home',
                url: 'https://www.thekinnegroup.com/sellers/marketing-your-home/',
              },
              {
                id: 'menu-item-mobile-sellers-showing-home',
                content: 'Showing Your Home',
                url: 'https://www.thekinnegroup.com/sellers/showing-your-home/',
              },
              {
                id: 'menu-item-mobile-sellers-adding-value',
                content: 'Adding Value',
                url: 'https://www.thekinnegroup.com/sellers/adding-value/',
              },
              {
                id: 'menu-item-mobile-sellers-free-market-analysis',
                content: 'Free Market Analysis',
                url: 'https://www.thekinnegroup.com/sellers/free-market-analysis/',
              },
            ],
          },
          {
            id: 'menu-item-mobile-about',
            content: 'About',
            url: 'https://www.thekinnegroup.com/about/',
          },
          {
            id: 'menu-item-mobile-contact',
            content: 'Contact',
            url: 'https://www.thekinnegroup.com/contact/',
          },
        ],
      },
    },
  ],
  footers: [
    {
      id: 'on-silba-footer-first',
      display: true,
      className: 'on-silba-footer-section-one',
      sectionClassName: 'on-silba-footer-section-container',
      columns: [
        {
          id: 'f-1-col-1',
          className: 'align-center on-silba-footer-img-container',
          style: {},
          items: [
            {
              id: 'f-1-col-1-img',
              type: 'image',
              className: 'on-silba-footer-image',
              style: {},
              linkUrl: 'https://www.thekinnegroup.com/',
              url: 'https://si-homelight.s3.amazonaws.com/sites/silba/logo-footer.png',
            },
          ],
        },
      ],
    },

    {
      id: 'on-silba-footer-second',
      display: true,
      className: 'on-silba-footer-section-two',
      sectionClassName: 'on-silba-footer-section-container on-silba-footer-section-container-two',
      columns: [
        {
          id: 'f-2-col-1',
          className: 'on-silba-footer-column col-md-3 col-sm-12',
          style: {},
          items: [
            {
              id: 'f-2-col-1-h1',
              type: 'heading',
              elementType: 'h1',
              className: '',
              style: {},
              data: 'CONTACT',
            },
            {
              id: 'f-2-col-1-item-1',
              type: 'heading',
              elementType: 'p',
              className: '',
              style: {},
              data: 'The Kinne Group at Compass',
            },
            {
              id: 'f-2-col-1-item-2',
              type: 'heading',
              elementType: 'p',
              className: 'mb-0',
              style: {},
              data: '4200 Westheimer Rd, Suite 1000',
            },
            {
              id: 'f-2-col-1-item-3',
              type: 'heading',
              elementType: 'p',
              className: 'mb-0',
              style: {},
              data: 'Houston, TX 77027',
            },
            {
              id: 'f-2-col-1-item-4',
              type: 'itemContent',
              className: 'mb-0',
              style: {},
              items: [
                {
                  content: 'M: ',
                },
                {
                  content: '(281) 610-3625',
                  url: 'tel:+12816103625',
                  className: 'on-silba-phone-link',
                },
              ],
            },
            {
              id: 'f-2-col-1-item-5',
              type: 'itemContent',
              className: 'mb-0',
              style: {},
              items: [
                {
                  content: 'E: ',
                },
                {
                  content: 'Email Us',
                  url: 'mailto:info@thekinnegroup.com',
                  className: 'on-silba-email-link',
                },
              ],
            },
          ],
        },
        {
          id: 'f-col-2',
          className: 'on-silba-footer-column col-md-3 col-sm-12',
          style: {},
          items: [
            {
              id: 'col-two-heading',
              type: 'heading',
              elementType: 'h1',
              className: '',
              style: {},
              data: 'CONNECT',
            },
            {
              id: 'col-two-socials',
              type: 'socialIcons',
              className: 'on-silba-footer-socials',
              style: {},
              items: [
                {
                  id: 'on-silba-fcb',
                  iconTypeClassName: 'fab',
                  iconClassName: 'facebook-f',
                  data: 'Facebook',
                  className: 'fbb',
                  url: 'https://www.facebook.com/TheKinneGroup',
                },
                {
                  id: 'on-silba-twt',
                  iconTypeClassName: 'fab',
                  iconClassName: 'twitter',
                  data: 'Twitter',
                  className: 'twitter',
                  url: 'https://twitter.com/StevenKinne',
                },
                {
                  id: 'on-silba-insta',
                  iconTypeClassName: 'fab',
                  iconClassName: 'instagram',
                  data: 'Instagram',
                  url: 'https://www.instagram.com/thekinnegroup/',
                },
                {
                  id: 'on-silba-linkedin',
                  iconTypeClassName: 'fab',
                  iconClassName: 'linkedin-in',
                  data: 'LinkedIn',
                  url: 'https://www.linkedin.com/in/steven-kinne-720711159',
                },
                {
                  id: 'on-silba-yt',
                  iconTypeClassName: 'fab',
                  iconClassName: 'youtube',
                  data: 'Youtube',
                  url: 'https://www.youtube.com/channel/UCFnTgsnzNgGD9O-z_1r7uYQ',
                },
              ],
            },
          ],
        },
        {
          id: 'col-three',
          className: 'on-silba-footer-column  col-md-3 col-sm-12',
          style: {},
          items: [
            {
              id: 'col-three-heading',
              type: 'heading',
              elementType: 'h1',
              className: '',
              style: {},
              data: 'NAVIGATION',
            },
            {
              id: 'col-three-menu',
              type: 'menu',
              className: 'on-silba-footer-menu',
              itemClassName: 'mb-0',
              style: {},
              items: [
                {
                  id: 'col-three-item-2',
                  data: 'Search',
                  url: 'https://www.thekinnegroup.com/property-search/search-form/',
                },
                {
                  id: 'col-three-item-4',
                  data: 'Communities',
                  url: 'https://www.thekinnegroup.com/communities/',
                },
                {
                  id: 'col-three-item-1',
                  data: 'Buyers',
                  url: 'https://www.thekinnegroup.com/buyers/',
                },
                {
                  id: 'col-three-item-3',
                  data: 'Sellers',
                  url: 'https://www.thekinnegroup.com/sellers/',
                },
                {
                  id: 'col-three-item-7',
                  data: 'About',
                  url: 'https://www.thekinnegroup.com/about/',
                },
                {
                  id: 'col-three-item-6',
                  data: 'Contact',
                  url: 'https://www.thekinnegroup.com/contact/',
                },
              ],
            },
          ],
        },
        {
          id: 'col-four',
          className: 'on-silba-footer-column col-md-3 col-sm-12',
          style: {},
          items: [
            {
              id: 'col-four-h1',
              type: 'heading',
              elementType: 'h1',
              className: '',
              style: {},
              data: 'POPULAR SEARCHES',
            },
            {
              id: 'col-four-menu',
              type: 'menu',
              className: 'on-silba-footer-menu',
              itemClassName: 'mb-0',
              style: {},
              items: [
                {
                  id: 'col-four-item-1',
                  data: 'Featured Listings',
                  url: 'https://www.thekinnegroup.com/property-search/results/?searchid=532555',
                },
                {
                  id: 'col-four-item-2',
                  data: 'Newest Listings',
                  url: 'https://www.thekinnegroup.com/property-search/results/?searchid=532556',
                },
                {
                  id: 'col-four-item-4',
                  data: 'Single Family',
                  url: 'https://www.thekinnegroup.com/property-search/results/?searchid=532557',
                },
                {
                  id: 'col-four-item-3',
                  data: 'Condos & Townhomes',
                  url: 'https://www.thekinnegroup.com/property-search/results/?searchid=532558',
                },
                {
                  id: 'col-four-item-5',
                  data: 'Luxury Homes',
                  url: 'https://www.thekinnegroup.com/property-search/results/?searchid=532560',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 'on-silba-small-footer',
      className: 'on-silba-footer-section-small',
      sectionClassName: 'on-silba-footer-section-container',
      columns: [
        {
          id: 'f-4-col-1',
          className: 'col-lg-6 on-silba-footer-notices',
          style: {},
          items: [
            {
              id: 'f-s-col-1-item-1',
              type: 'heading',
              className: '',
              data: 'Texas Real Estate Commission Consumer Protection Notice',
              url: 'https://www.thekinnegroup.com/res/includes/TREC-CPN.pdf',
            },
            {
              id: 'f-s-col-1-item-2',
              type: 'heading',
              className: '',
              data: 'Texas Real Estate Commission Information About Brokerage Services',
              url: 'https://www.thekinnegroup.com/res/includes/TREC-IABS.pdf',
            },
          ],
        },
      ],
    },
    {
      id: 'on-silba-footer-third',
      display: true,
      className: 'on-silba-footer-section-three',
      sectionClassName: 'on-silba-footer-section-container',
      columns: [
        {
          id: 'f-3-col-1',
          className: 'col-lg-6',
          style: {},
          items: [
            {
              id: 'f-3-col-1-item-1',
              type: 'menu',
              className: 'on-silba-footer-privacy-menu',
              style: {},
              items: [
                {
                  id: 'f-3-col-1-menu-1',
                  data: 'Accessibility',
                  className: 'on-silba-footer-privacy-menu-item-first',
                  url: 'https://www.thekinnegroup.com/accessibility/',
                },
                {
                  id: 'f-3-l-1',
                  data: '|',
                },
                {
                  id: 'f-3-col-1-menu-2',
                  data: 'Terms of Service',
                  url: 'https://www.thekinnegroup.com/terms-of-service/',
                },
                {
                  id: 'f-3-l-2',
                  data: '|',
                },
                {
                  id: 'f-3-col-1-menu-3',
                  data: 'Privacy Policy',
                  url: 'https://www.thekinnegroup.com/privacy-policy/',
                },
                {
                  id: 'f-3-l-3',
                  data: '|',
                },
                {
                  id: 'f-3-col-1-menu-4',
                  data: 'DMCA Notice',
                  url: 'https://www.thekinnegroup.com/dmca-notice/',
                },
                {
                  id: 'f-3-l-4',
                  data: '|',
                },
                {
                  id: 'f-3-col-1-menu-5',
                  data: 'Property Listings',
                  url: 'https://www.thekinnegroup.com/property-search/site-map/',
                },
                {
                  id: 'f-3-l-5',
                  data: '|',
                },
                {
                  id: 'f-3-col-1-menu-6',
                  data: 'Sitemap',
                  url: 'https://www.thekinnegroup.com/site-map/',
                },
              ],
            },
            {
              id: 'f-3-col-1-item-2',
              type: 'heading',
              className: '',
              style: {},
              data: '© Copyright 2022 The Kinne Group. All Rights Reserved.',
            },
          ],
        },
        {
          id: 'f-3-col-2',
          className: 'col-lg-2 on-footer-silba-img-col',
          style: {},
          items: [
            {
              id: 'f-3-col-2-item-1',
              type: 'image',
              className: 'on-silba-footer-realtor-logo',
              url: 'https://si-homelight.s3.amazonaws.com/sites/silba/logo-footer-equal-realtor.png',
            },
          ],
        },
        {
          id: 'f-3-col-3',
          className: 'col-lg-4 on-silba-f-3-menu-col',
          style: {},
          items: [
            {
              id: 'f-3-col-3-menu',
              type: 'menu',
              className: 'on-silba-footer-menu-sierra col-lg-4',
              itemClassName: 'mb-0',
              style: {},
              url: 'https://www.sierrainteractive.com/',
              items: [
                {
                  id: 'col-3-item-1-real-estate',
                  data: 'Real Estate Websites by ',
                  url: 'https://www.sierrainteractive.com/',
                },
                {
                  id: 'col-3-item-2-sierra',
                  data: 'Sierra Interactive',
                  url: 'https://www.sierrainteractive.com/',
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  substitute: {},
  replace: {
    'tradeIn.hero.className': 'heroClassName',
    'cashOffer.hero.className': 'heroClassName',
  },
  siteData: {
    agency: 'The Kinne Group',
    agentDescription: 'Top agents in Greater Houston Area in Texas',
    agentAvatar: 'https://si-homelight.s3.amazonaws.com/sites/silba/agent.jpg',
    heroBackground: 'https://si-homelight.s3.amazonaws.com/sites/silba/hero-bg.webp',
    heroClassName: 'on-silba-hero',
  },
};
